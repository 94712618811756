/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';

export default function App() {
	const [region, setRegion] = useState('');

	const redirectRegion = (value) => {
		if (value === 'us') {
			window.location.replace('https://us.adagiomedical.com');
		}

		if (value === 'eu') {
			window.location.replace('https://eu.adagiomedical.com');
		}
	};

	return (
		<div>
			<div className='flex flex-col items-center justify-center w-full bg-gray-200 min-h-screen px-4 pb-10'>
				<img
					className='block h-48 w-auto'
					src='https://firebasestorage.googleapis.com/v0/b/adagio-cms.appspot.com/o/common%2Fadagio-logo.svg?alt=media&token=3c4f96a0-09c3-4600-9640-960bb1de1f95'
					alt='logo'
				/>
				<div className='mt-8 flex w-full justify-center'>
					<span className='block mr-5 pl-8 font-bold md:text-2xl text-gray-400 text-xl'>
						Please select your region of interest
					</span>
				</div>
				<div className='mt-8'>
					<span className='mr-5'>
						<a
							href='/'
							className='font-medium text-lg text-primary-blue hover:text-secondary-blue'
							onClick={(e) => {
								e.preventDefault();
								redirectRegion('us');
								// setOpen(true);
							}}
						>
							United States
						</a>
					</span>
					{'|'}
					<span className='ml-5'>
						<a
							href='/'
							className='font-medium text-lg text-primary-blue hover:text-secondary-blue'
							onClick={(e) => {
								e.preventDefault();
								redirectRegion('eu');
								// setOpen(true);
							}}
						>
							Europe
						</a>
					</span>
				</div>
			</div>
		</div>
	);
}
